// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-adatvedelmi-tajekoztato-tsx": () => import("./../../../src/pages/adatvedelmi-tajekoztato.tsx" /* webpackChunkName: "component---src-pages-adatvedelmi-tajekoztato-tsx" */),
  "component---src-pages-altalanos-szerzodesi-feltetelek-tsx": () => import("./../../../src/pages/altalanos-szerzodesi-feltetelek.tsx" /* webpackChunkName: "component---src-pages-altalanos-szerzodesi-feltetelek-tsx" */),
  "component---src-pages-idopont-foglalas-tsx": () => import("./../../../src/pages/idopont-foglalas.tsx" /* webpackChunkName: "component---src-pages-idopont-foglalas-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-ops-tsx": () => import("./../../../src/pages/ops.tsx" /* webpackChunkName: "component---src-pages-ops-tsx" */),
  "component---src-pages-rolunk-tsx": () => import("./../../../src/pages/rolunk.tsx" /* webpackChunkName: "component---src-pages-rolunk-tsx" */),
  "component---src-pages-szolgaltatasok-tsx": () => import("./../../../src/pages/szolgaltatasok.tsx" /* webpackChunkName: "component---src-pages-szolgaltatasok-tsx" */)
}

